<template>
    <div class="ds__page">
        <header class="ds__header">
            <h1 class="title ds__title">
                Inputs
            </h1>
        </header>

        <section class="ds__section">
            <header class="ds__header">
                <h2 class="subtitle ds__subtitle">
                    Regular
                </h2>
            </header>

            <div class="ds__sub-section">
                <div class="row ds__row">
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.text"
                            hint="Dit is een input field hint"
                            label="Text"
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.text"
                            hint="Dit is een input field hint"
                            label="Required"
                            required
                            persistent-hint
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.text"
                            disabled
                            hint="Dit is een input field hint"
                            label="Disabled"
                            persistent-hint
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.text"
                            error
                            error-messages="Error message"
                            label="Text with error"
                            hint="Dit is een input field hint"
                            persistent-hint
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.text"
                            error
                            label="Error with required"
                            required
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.text"
                            disabled
                            error
                            error-messages="Error message"
                            label="Error Disabled"
                            required
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.email"
                            autocomplete="email"
                            :disabled="loading > 0"
                            label="Email"
                            required
                            type="email"
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputText
                            v-model="formValues.password"
                            autocomplete="current-password"
                            :disabled="loading > 0"
                            label="Password"
                            required
                            type="password"
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputFile
                            v-model="formValues.file"
                            label="Bijlage"
                        />
                    </div>
                </div>
            </div>
        </section>

        <section class="ds__section">
            <header class="ds__header">
                <h2 class="subtitle ds__subtitle">
                    Textarea
                </h2>
            </header>
            <div class="ds__sub-section">
                <div class="row ds__row">
                    <div class="column column--4/12">
                        <AppInputTextarea
                            v-model="formValues.textarea"
                            hint="Dit is een input field hint"
                            label="Textarea"
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputTextarea
                            v-model="formValues.textarea"
                            hint="Dit is een input field hint"
                            label="Required Textarea"
                            persistent-hint
                            required
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputTextarea
                            v-model="formValues.textarea"
                            disabled
                            hint="Dit is een input field hint"
                            label="Disabled Textarea"
                            persistent-hint
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputTextarea
                            v-model="formValues.textarea"
                            error
                            label="Textarea with error"
                            hint="Dit is een input field hint"
                            persistent-hint
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputTextarea
                            v-model="formValues.textarea"
                            error
                            error-messages="Error message"
                            label="Error with required"
                            required
                        />
                    </div>
                    <div class="column column--4/12">
                        <AppInputTextarea
                            v-model="formValues.textarea"
                            error
                            error-messages="Error message"
                            label="Error Disabled"
                            required
                            disabled
                        />
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import AppInputFile from "@/components/AppInputFile";
import AppInputText from "@/components/AppInputText";
import AppInputTextarea from "@/components/AppInputTextarea";
export default {
    name: "DsInputs",
    components: { AppInputFile, AppInputTextarea, AppInputText },
    data() {
        return {
            formValues: {
                file: "",
                text: "",
                textarea: "",
                email: "",
                password: ""
            }
        };
    }
};
</script>
